import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import LineDetail from "./pages/LineDetail";
import {initLocalStorage} from "./store/LocalStorage";
import Setting from "./pages/Setting";
import {StrictMode} from "react";
import axios from "axios";
import './i18n'
import {DbContextProvider} from "./context/DbContext";
import {compress as compressJson, decompress as decompressJson} from "lzutf8";
import {location} from "./libs/location";
const CURRENT_DB_VERSION = "1.2"
async function fetchData (){
    const db = localStorage.getItem('db')
    const dbVersion = localStorage.getItem('dbVersion')
    if (db && dbVersion === CURRENT_DB_VERSION) {
        console.log("latest db version")
        return
    }
    let data = {}
    const[ ctb,
        kmb,
        gmb,
        nlb,
        stopCTB,
        stopKMB,
        stopGMB,
        stopNLB] = await Promise.all([
        axios.get('https://louis-ni.github.io/stationBuddy-route/ctb.json'),
        axios.get('https://louis-ni.github.io/stationBuddy-route/kmb.json'),
        axios.get('https://louis-ni.github.io/stationBuddy-route/gmb.json'),
        axios.get('https://louis-ni.github.io/stationBuddy-route/nlb.json'),
        axios.get('https://louis-ni.github.io/stationBuddy-route/stop-ctb.json'),
        axios.get('https://louis-ni.github.io/stationBuddy-route/stop-kmb.json'),
        axios.get('https://louis-ni.github.io/stationBuddy-route/stop-gmb.json'),
        axios.get('https://louis-ni.github.io/stationBuddy-route/stop-nlb.json')
    ])
    data['routeDB'] = ctb.data.concat(kmb.data).concat(gmb.data).concat(nlb.data)
    const stopDB = stopCTB.data.concat(stopKMB.data).concat(stopGMB.data).concat(stopNLB.data)
    const stopDBMap = {}
    for (const stop of stopDB) {
        const key = stop.co +'-' + stop.stopId
        stopDBMap[key] = stop
    }
    data['stopDB'] = stopDBMap
    localStorage.setItem('db', compressJson(JSON.stringify(data), { outputEncoding: "Base64" }))
    localStorage.setItem('dbVersion', CURRENT_DB_VERSION)
}

// init data when app loading
if (typeof window != 'undefined') {
    await fetchData()
    initLocalStorage()
    location.updateLocation()
}


function App() {
    const db = localStorage.getItem("db")
    const dbData = JSON.parse(decompressJson(db, { inputEncoding: "Base64" }))
    const language = localStorage.getItem("language") === null || localStorage.getItem("language") === "" ? "tc" : localStorage.getItem("language")
    // i18n.changeLanguage(language)

    // function renderNextStepLang() {
    //     if (userSelectedLang === "tc" || userSelectedLang === "sc"){
    //         return "下一步"
    //     } else {
    //         return "Next Step"
    //     }
    // }

    // function finishLangSetting(){
    //     setLang(userSelectedLang)
    //     i18n.changeLanguage(userSelectedLang)
    //     localStorage.setItem("language", userSelectedLang)
    // }

    if (dbData == null) {
        return <div></div>
    }

  return (
      <div className="w-full relative">
          <StrictMode>
              <DbContextProvider initialDB={dbData}>
                  <Routes>
                      <Route path="/" element={<Navigate to={`/${language}`} />} />
                      <Route path="/:lang" element={<Home />}>
                      </Route>
                      <Route path="/:lang/routeDetail" element={<LineDetail/>} />
                      <Route path="/setting" element={<Setting/>} />
                  </Routes>
              </DbContextProvider>
          </StrictMode>
      </div>
  );
}

export default App;
