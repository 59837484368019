import {nameFormater} from "../../libs/nameFormater";

// import { useState } from "react";
import {getMinutesAhead} from "../../utils/TimeCoverter";
import {useTranslation} from "react-i18next";
import {Button} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import BuddyFonts from '../fonts/BuddyFonts';

function CardComponent({collection, busColor, isLoading, busArrivalTime, toggleCollectionEvent, lang, showAddedCollection, addDefaultStationToCollection}) {
    // const [toggle, setToggle] = useState(Array(collection.length).fill(false))
    //  const [hideScheduleSentence, setHideScheduleSentence] = useState(Array(collection.length).fill(false))
    const {t} = useTranslation();
    const navigate = useNavigate();
    // console.log(busArrivalTime)

    // function stationToggle(index) {
    //     const newToggle = [...toggle];
    //     newToggle[index] = !newToggle[index];
    //     setToggle(newToggle);
    //     if (lang !== "en"){
    //         const newHideScheduleSentence = [...hideScheduleSentence]
    //         newHideScheduleSentence[index] = !newHideScheduleSentence[index]
    //         setHideScheduleSentence(newHideScheduleSentence)
    //     }
    // }
    

    

    function toRouteDetail(collection){
        let url = `/${lang}/routeDetail?co=${collection.co}&route=${collection.route}&direction=${collection.direction}&destination=${collection.destination.en}&defaultSeq=${collection.seq}`
        if (collection.co === "NLB"){
            url = url + `&routeId=${collection.routeId}`
        }

        navigate(url)
    }
    function renderBusEta(index, collection) {
        const key = collection.co + "-" + collection.route + "-" + collection.stopId
        let etaTime = ""
        let etaTimeArr = []
        if (!isLoading){
            if (collection.co === "KMB"){
                const etaArr = busArrivalTime[key].filter(eta=> eta.seq.toString() === collection.seq.toString())
                if (etaArr[0].eta === "" || etaArr[0].eta === null){
                    etaTime = ""
                }else{
                    const formatedETA = getMinutesAhead(etaArr[0].eta)
                    etaTime = formatedETA < 0 ? "-": formatedETA
                    etaTimeArr = etaArr.map(eta => eta.eta)
                }
            } else if (collection.co === "CTB"){
                if (busArrivalTime[key].length === 0){
                    etaTime = ""
                }else if (busArrivalTime[key][0].eta === "" || busArrivalTime[key][0].eta === null){
                    etaTime = ""
                } else{
                    etaTime = getMinutesAhead(busArrivalTime[key][0].eta) < 0 ? "-": getMinutesAhead(busArrivalTime[key][0].eta)
                    etaTimeArr = busArrivalTime[key].filter(eta => eta.dir === collection.direction).map(eta => eta.eta)
                }
            } else {
                if (busArrivalTime[key].length === 0){
                    etaTime = ""
                }else if (busArrivalTime[key][0].eta === "" || busArrivalTime[key][0].eta === null){
                    etaTime = ""
                } else{
                    etaTime = getMinutesAhead(busArrivalTime[key][0].eta) < 0 ? "-": getMinutesAhead(busArrivalTime[key][0].eta)
                    etaTimeArr = busArrivalTime[key].map(eta => eta.eta)
                }
            }
        }
        // sort eta 
        if (etaTimeArr.length > 0){
            etaTimeArr = etaTimeArr.map(eta => getMinutesAhead(eta)).sort((a, b) => a - b)
        }
        // <i className={`fa-solid ${toggle[index] ? 'fa-chevron-up' : 'fa-chevron-down'} text-[#A3A3A3] ml-1`}></i>
        // <p className="text-[#A3A3A3] text-base font-quickSand ml-1 whitespace-nowrap">Scheduled</p>
        return (
            <div className='flex flex-row pl-4 pr-4 mt-1 mb-4'>
                <div className='flex flex-row items-center h-6' >
                    {
                        etaTime === "" ? <div className="text-xs text-white font-quickSand ml-1 ">
                                <p className=''>{t('no_arrival_time')}</p></div>
                            :
                            <div className='flex flex-row items-center h-6' >
                                {
                                    !isLoading && etaTimeArr.map((min, index) => {
                                        // const min = getMinutesAhead(eta)
                                        return <div className="text-sm text-white font-quickSand ml-1" key={index}>
                                            {min < 0 ? <span className="text-base">-</span> : <span className="text-base">{min} </span>}
                                            <span className="text-[#A3A3A3] text-base font-quickSand ml-1 whitespace-nowrap">{t("minute_unit")} </span>
                                            {index !== etaTimeArr.length-1 ? "," : ""}
                                        </div>
                                    })
                                }
                            </div>
                    }
                </div>

                
            </div>
        )
    }

  return (
    <div className='flex flex-col w-full bg-searchBox-grey rounded-2xl mb-4'>
        {/**
         * bus number div
         */}
        <div className="flex flex-row items-center w-full h-6 pl-4 pr-4 mt-4 mb-4">
            <div className={`flex flex-row items-center  h-6 rounded-lg ${busColor} pl-2 pr-2`}>
                <span className={`text-font-212121 font-bold text-lg font-quickSand`}>{collection[0].route}</span>
            </div>
            <span className="text-s text-gray-400 font-quickSand mr-1 ml-2 ">To</span>
            <p className="font-bold text-base text-white font-quickSand pl-2 max-w truncate">
                {collection[0].destination[lang]}
            </p>
        </div>
        <div className='h-px bg-[#FFFFFF] opacity-[.1]'></div>

        {/* stop div */}
        <ul>
        {
            collection.sort((a, b) => Number(a.seq) - Number(b.seq)).map((item, index) => {
                return (
                     <li key={index} onClick={() => toRouteDetail(item)}>
                        <div className="flex flex-row w-full justify-between items-center pl-4 pr-4 mt-3">
                            <p className="text-[#A3A3A3] text-base font-quickSand ml-1 whitespace-nowrap">
                                {t("to_stop")}
                            </p>
                            <span className="text-white text-base font-bold font-quickSand ml-2 truncate">{lang === "en" ? nameFormater.renderStationName(item.savedStation[lang]): item.savedStation[lang]}</span>
                            <i className="fa-solid fa-minus text-white ml-auto" onClick={(e) => {e.stopPropagation(); toggleCollectionEvent([item])}}></i>
                        </div>
                         { renderBusEta(index, item) }
                        {index !== collection.length-1 && <div className='h-px bg-[#FFFFFF] opacity-[.1] ml-3 mr-3'></div>}
                    </li>
                )
            })
        }
        </ul>
    
        <div className='h-px bg-[#FFFFFF] opacity-[.1]'></div>
        {
            showAddedCollection === true ?
                <div className='flex flex-row items-center pl-4 pr-4 pt-2 pb-4'>
                    <Button className='w-full rounded-full mt-4 bg-white' onClick={addDefaultStationToCollection}>


                        <p className={` ${BuddyFonts.ButtonS} font-semibold text-fnt-212121`}>+ {t('add_to_collection')}</p>
                    </Button>
                </div>
                :
                <div className='flex flex-row items-center pl-4 pr-4 pt-2 pb-4'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.95 9.35L5.3375 7.7375C5.2 7.6 5.025 7.53125 4.8125 7.53125C4.6 7.53125 4.425 7.6 4.2875 7.7375C4.15 7.875 4.08125 8.05 4.08125 8.2625C4.08125 8.475 4.15 8.65 4.2875 8.7875L6.425 10.925C6.575 11.075 6.75 11.15 6.95 11.15C7.15 11.15 7.325 11.075 7.475 10.925L11.7125 6.6875C11.85 6.55 11.9188 6.375 11.9188 6.1625C11.9188 5.95 11.85 5.775 11.7125 5.6375C11.575 5.5 11.4 5.43125 11.1875 5.43125C10.975 5.43125 10.8 5.5 10.6625 5.6375L6.95 9.35ZM8 15.5C6.9625 15.5 5.9875 15.3031 5.075 14.9094C4.1625 14.5156 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.48438 11.8375 1.09063 10.925C0.696875 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48438 5.075 1.09063C5.9875 0.696875 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.3031 10.0125 14.9094 10.925C14.5156 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5Z"
                            fill="#0AE1BC"/>
                    </svg>
                    <div className="text-xs text-gray-400 font-quickSand ml-1 grow">{t('collection_added')}</div>
                    <div className="justify-end text-xs text-white ml-1 font-quickSand font-bold uppercase" onClick={() => toggleCollectionEvent(collection)}>
                        <i className="fa-solid fa-minus font-bold mr-1"></i>
                        {t('remove')}
                    </div>
                </div>
        }


    </div>
  );

}

export default CardComponent;
